import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { CallToAction, Accordion, ProductPricing } from "../components/site/";

const Pricing = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, [props.location.origin, props.location.pathname, props.location.search]);

  const FAQs = [
    {
      q: "What types of payment do you accept?",
      a: <p>We accept Visa & MasterCard or direct debit payments.</p>,
    },
    {
      q: "When will I be billed?",
      a: (
        <p>
          We bill at the start of each month for usage in the previous month.
        </p>
      ),
    },
    {
      q: "How does the pricing work?",
      a: (
        <>
          <p>
            Monthly billing is based on your usage. That way you’re not paying
            for inactive employees, such as seasonal employees during the off
            season.
          </p>
          <p>
            Your monthly charge is made up of a base fee, and a per-employee
            charge based on the number of unique employees paid or with a
            timesheet entry in the month. If you have an employee who isn’t
            included in a pay run or timesheet during the calendar month, you
            won’t be charged for them.
          </p>
        </>
      ),
    },
    // {
    //   q: "Is there a discount for using multiple FlexiTime products?",
    //   a: (
    //     <>
    //       <p>
    //         If you use both PayHero and <Link to="/rostering">Droppah</Link>{" "}
    //         you’ll pay just $3 per employee per month for Droppah with no
    //         monthly base fee.
    //       </p>
    //       <p>
    //         Contact <a href="mailto:support@droppah.com">support@droppah.com</a>{" "}
    //         after subscribing to both products to have your discounted pricing
    //         applied.
    //       </p>
    //     </>
    //   ),
    // },
    {
      q: "Am I locked in?",
      a: (
        <p>
          No. Our standard subscriptions are pay-as-you-go and you can cancel
          your account at any time from your account settings.
        </p>
      ),
    },
    {
      q: "What happens if I cancel my account?",
      a: (
        <p>
          You can cancel at any time and we’ll send you a final invoice for your
          usage in the current billing period.
        </p>
      ),
    },
    {
      q: "Can I try before I buy?",
      a: (
        <>
          <p>
            You sure can! PayHero has a free trial that gives you access to all
            the features. You can choose to subscribe any time during or after
            the trial. All information you enter during the trial period will be
            retained.
          </p>
          <p>
            We also have a <a href="https://payhero.co.nz/interactive-demo" target="_blank">
            demo account</a> pre-loaded with some example employees that you can have a play
            around with at any time.
          </p>
        </>
      ),
    },
    {
      q:
        "I’m coming from another payroll system, how do I get set up?",
      a: (
        <p>
          Our customer success team can help get your data transferred across so you can get up and running with PayHero. Just email {" "} <a href="mailto:support@payhero.co.nz">support@payhero.co.nz</a> to get started.
        </p>
      ),
    },
    {
      q: "Do I need to arrange a Letter of Credit with my bank?",
      a: (
        <>
          <p>
            No, we don't require a letter of credit.{" "}
          </p>
          <p>
            You can pay your employees and Inland Revenue by direct debit, in-app with POLi, or download the bank files you need to make payments in your own time.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Seo
        title="Pricing | Payroll | PayHero"
        description="Spend less time on payroll & fly through payday filing with PayHero - compliant and easy to use online payroll software."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper noPaddingBottom maxWidth={800}>
          <Box stackGap={80}>
            <Box className="-textCenter">
              <h1>Pricing</h1>
              <h4>
                Find the right solution for your business and get started in
                minutes, no credit card needed. We'll even help you run your
                first pay.
              </h4>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper maxWidth={1300} stackGap={70}>
          <ProductPricing selectedProduct="payhero" />
          <Box className="-textCenter" stackGap={15}>
            <p>
              <Link to="/contact">Contact sales</Link> to request enterprise
              pricing options for larger organisations.
            </p>
            <p>
              Interested in becoming a partner?{" "}
              <a href="https://partners.flexitime.works" target="_blank">
                Find out more
              </a>
              .
            </p>
            <p css={{ color: "#999" }}>Prices are in NZD and exclude GST.</p>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper maxWidth={800}>
          <Box stackGap={60}>
            <h2 className="-textCenter">Frequently asked questions</h2>
            <Box stackGap={15}>
              {FAQs.map((item, i) => {
                return (
                  <Accordion key={i} title={item.q} light>
                    {item.a}
                  </Accordion>
                );
              })}
            </Box>
            <h4 className="-textCenter">
              More questions? <Link to="/contact">Talk to Sales</Link>.
            </h4>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default Pricing;
